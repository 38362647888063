import React from "react";
import DefaultLayout from "../../components/DefaultLayout";
import Hero from "../../components/bricks/Hero";
import Document from "../../components/Document";
import FeatureOverview from "../../components/FeatureOverview";
import ThemeBox from "../../components/bricks/ThemeBox";
import {themeBright, themeDark} from "../../page-styles/landingpage.css";
import ThemedSingleFeature from "../../components/bricks/ThemedSingleFeature";

const Features = ({location}) => (
  <DefaultLayout
    title="Source Control Integrations"
    description="Codecks' GitHub, Bitbucket and GitLab integrations allow you to attach your commit messages directly to your cards."
    location={location}
  >
    <Hero size="sm" title="Source Control Integrations" />
    <ThemeBox theme={themeBright} withBorder>
      <ThemedSingleFeature childAsDescription title="How does it work?" img="scm.png">
        <Document>
          <p>
            Our source control integrations allows you to reference card ids in your commit
            messages:
          </p>
          <pre>
            <code>$ git commit -am 'Increased size of hit boxes. Fix $3f1'</code>
          </pre>
          <pre>
            <code>$ git commit -am 'Added animations - please review $2xr @sarah'</code>
          </pre>
          <pre>
            <code>$ git commit -am 'Refactored god class in preparation for $5ta'</code>
          </pre>

          <p>
            This allows you to either mark a card as done or even start a review without having to
            leave your terminal.
          </p>
        </Document>
      </ThemedSingleFeature>
    </ThemeBox>

    <ThemeBox theme={themeDark} withBorder title="How to set it up?">
      <ThemedSingleFeature childAsDescription title="GitHub" img="scm-github.png">
        First, go to the Organization Settings and find the GitHub tab. You will then be asked to
        authenticate via a button. Once this succeeded, you will be able to select and connect a
        repository.
      </ThemedSingleFeature>

      <ThemedSingleFeature
        childAsDescription
        title="Bitbucket & GitLab"
        img="scm-other.png"
        imgRight
      >
        The procedure for Bitbucket and GitLab is fairly similar: Find the corresponding tabs in
        your Organization settings and find the <b>Create Webhook Url</b> button. This will create a
        url that you need to enter into your Bitbucket/GitLab settings. Follow the instructions
        within the settings, if you need help!
      </ThemedSingleFeature>
    </ThemeBox>
    <FeatureOverview />
  </DefaultLayout>
);

export default Features;
